<template>
  <h2 class="bright">Vos contacts</h2>
  <button class="add-button" @click="displayForm()">
    Ajouter un contact
  </button>
  <form v-if="isFormShown" autocomplete="off">
    <fieldset>
      <div class="errors" v-if="errors.length > 0">
        <p>Champs requis :</p>
        <ul>
          <li v-for="error in errors" :key="error">
            {{ error }}
          </li>
        </ul>
      </div>

      <input v-model="name" type="text" placeholder="Nom" id="name" />
      <input
        v-model="firstname"
        type="text"
        placeholder="Prénom"
        id="firstname"
      />
      <input
        v-model="phone"
        type="tel"
        placeholder="Numéro de téléphone"
        id="phone"
        maxlength="10"
        required
      />
      <input
        v-model="email"
        type="email"
        name=""
        placeholder="Adresse email"
        id="email"
      />
      <input
        type="number"
        name=""
        id=""
        placeholder="Budget"
        v-model="budget"
      />
      <div>
        <strong>Type de recherche</strong>
        </div>
      <div class="flex-wrap checkboxes">
        <div>
          <input type="checkbox" v-model="isHouse" id="house" />
          <label for="house">Maison</label>
        </div>
        <div>
          <input type="checkbox" v-model="isAppartment" id="appartment" />
          <label for="appartment">Appartement</label>
        </div>
        <div>
          <input type="checkbox" v-model="isParking" id="parking" />
          <label for="parking">Parking</label>
        </div>
        <div>
          <input type="checkbox" v-model="isLand" id="land" />
          <label for="land">Terrain</label>
        </div>
      </div>
      <div class="relative">
        <div
          class="city-selector"
          v-if="citiesFromZipcode.length > 0 || citiesFromName.length > 0"
        >
          <div
            v-for="city in citiesFromZipcode"
            :key="city"
            @click="setCity(city.label, city.zip_code)"
          >
            {{ city.zip_code }} - {{ city.label }}
          </div>
          <div
            v-for="city in citiesFromName"
            :key="city"
            @click="setCity(city.label, city.zip_code)"
          >
            {{ city.zip_code }} - {{ city.label }}
          </div>
        </div>
        <input
          autocomplete="off"
          placeholder="Code postal de recherche"
          type="text"
          name=""
          id="zipcode"
          v-model="targetZipcode"
          @focus="clearZip()"
        />
      </div>
      <div>
        <label for="city" v-if="contactCityList.length > 0"
          >Villes sélectionnées</label
        >
        <div class="flex-wrap">
          <div
            class="city-item"
            v-for="(city, index) in contactCityList"
            :key="city"
            @click="removeCity(index)"
          >
            {{ city }} <span>&#10006;</span>
          </div>
        </div>
      </div>
      <textarea
        name=""
        id=""
        cols="30"
        rows="5"
        placeholder="Notes complémentaires"
        v-model="observations"
      ></textarea>
      <button @click.prevent="validateContact()">Enregistrer</button>
    </fieldset>
  </form>
  <div class="properties">
    <ContactList :list="sortedContactList"></ContactList>
  </div>
</template>

<script>
import cityList from "@/assets/cities.json";
import router from "@/router";
import ContactList from "./ContactList.vue";

export default {
  components: { ContactList },
  data() {
    return {
      targetZipcode: "",
      isFormShown: false,

      name: "",
      firstname: "",
      phone: "",
      email: "",
      budget: "",
      contactCityList: [],
      observations: "",
      isHouse: false,
      isAppartment: false,
      isParking: false,
      isLand: false,
      contactListDatas: [],
      errors: [],
    };
  },
  computed: {
    citiesFromZipcode() {
      let cities = [];
      if (this.targetZipcode.length > 2) {
        cities = Object.values(cityList.cities).filter((city) =>
          city["zip_code"].startsWith(this.targetZipcode)
        );
      }
      return cities;
    },
    citiesFromName() {
      let cities = [];
      if (this.targetZipcode.length > 2) {
        cities = Object.values(cityList.cities).filter((city) =>
          city["label"].includes(this.targetZipcode)
        );
      }

      return cities;
    },
    contactListfromStore() {
      return this.$store.state.contactList;
    },
    sortedContactList() {
      const data = [...this.contactListfromStore];
      data.sort((a, b) => a.name.localeCompare(b.name));

      return data;
    },
    userRole() {
      return this.$store.state.stripeRole;
    },
  },
  methods: {
    navigate(target) {
      router.push(target);
    },
    setCity(city, zipcode) {
      this.contactCityList.push(zipcode + " " + city);
      this.clearZip();
    },
    clearZip() {
      this.targetZipcode = "";
    },
    displayForm() {
      if (this.userRole) {
        this.isFormShown = !this.isFormShown
      } else {
        return this.navigate('/prices')
      }
    },
    removeCity(index) {
      this.contactCityList.splice(index, 1);
    },
    async validateContact() {
        this.validateForm();
        if (this.errors.length == 0) {
          const contactListDatas = {
            id: Date.now(),
            name: this.name,
            firstname: this.firstname,
            phone: this.phone,
            email: this.email,
            budget: this.budget,
            contactCityList: Object.values(this.contactCityList),
            observations: this.observations,
            isHouse: this.isHouse,
            isAppartment: this.isAppartment,
            isParking: this.isParking,
            isLand: this.isLand,
          };
          this.$store.dispatch("addContact", contactListDatas);
          this.clearForm();
          this.saveContact();
        }
      
    },

    clearForm() {
      this.name = "";
      this.firstname = "";
      this.phone = "";
      this.email = "";
      this.budget = 0;
      this.contactCityList = [];
      this.observations = "";
      this.isHouse = false;
      this.isAppartment = false;
      this.isParking = false;
      this.isLand = false;

      this.isFormShown = false;
    },

    saveContact() {
      this.$store.dispatch("saveContactList");
      this.isEdited = false;
    },
    validateForm() {
      this.errors = [];
      if (this.name == "") {
        this.errors.push("Nom");
      }
      if (this.firstname == "") {
        this.errors.push("Prénom");
      }
      if (this.phone == "") {
        this.errors.push("Numéro de téléphone");
      }
      if (this.email == "") {
        this.errors.push("Adresse email");
      }
      if (
        this.isHouse == false &&
        this.isAppartment == false &&
        this.isParking == false &&
        this.isLand == false
      ) {
        this.errors.push("Un type de bien");
      }
      if (this.contactCityList.length < 1) {
        this.errors.push("Code postal");
      }
    },
  },
};
</script>

<style lang="scss">
.city-list {
  display: flex;
  flex-wrap: wrap;
}
.contact {
  background: white;

  a {
    color: #454da1;
  }
}
.observations {
  text-transform: capitalize;
}
.city-selector {
  position: absolute;
  top: 0;
  width: 94%;
  min-height: 100px;
  max-height: 248px;
  margin: 0 auto;
  background: white;
  overflow-y: scroll;
  box-shadow: 0px 0px 21px #ccc;
  padding: 0 3%;

  div {
    cursor: pointer;
    height: 30px;
    line-height: 30px;

    &:hover {
      background: #ccc;
    }
  }
}
.city-item {
  color: #3e356b;
  background: #ede9ff;
  /* border: 1px solid #ada1eb; */
  padding: 8px 20px;
  border-radius: 3px;
  margin: 4px 4px 0 0;
  line-height: 12px;

  span {
    color: red;
  }
}

</style>
