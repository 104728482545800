<template>
  <div class="notification" v-if="!isMailVerified && isLoggedIn">
    Merci de vérifier votre adresse email afin d'accéder à l'application
  </div>
  <nav>
    <button class="back" @click="goBack()">
      <font-awesome-icon icon="chevron-left" />
    </button>
    <div>
      <button v-if="isLoggedIn" @click="router.push('/properties')">
        <font-awesome-icon icon="house" />
        <div>Biens</div>
      </button>
      <button v-if="isLoggedIn" @click="router.push('/contacts')">
        <font-awesome-icon icon="user" />
        <div>Contacts</div>
      </button>
      <button v-if="!isLoggedIn" @click="router.push('/login')">
        <font-awesome-icon icon="user" />
        <div>Connexion</div>
      </button>
      <button v-if="!isLoggedIn" @click="router.push('/register')">
        <font-awesome-icon icon="user" />
        <div>Inscription</div>
      </button>
      <button v-if="isLoggedIn" @click="router.push('/dashboard')">
        <font-awesome-icon icon="info" />
        <div>Infos</div>
      </button>
      <button @click="handleSignOut()" v-if="isLoggedIn">
        <font-awesome-icon icon="right-to-bracket" />
        <div>Quitter</div>
      </button>
    </div>
  </nav>
  <div class="wrapper">
    <router-view />
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useRouter } from "vue-router";
import store from "@/store/";

const router = useRouter();
const isLoggedIn = ref(false);
const isMailVerified = ref(false);

let auth;
onMounted(() => {
  auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      isLoggedIn.value = true;
    } else {
      isLoggedIn.value = false;
    }
    if (user && user.emailVerified) {
      isMailVerified.value = true;
      store.dispatch("getCustomClaimRole");
      store.dispatch("realtimeRetrieveContacts");
      store.dispatch("realtimeRetrieveProperties");
      store.dispatch("retrieveInfos");
    } else {
      isMailVerified.value = false;
    }
    router.beforeEach(async (to, from, next) => {
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        const currentUser = await auth.currentUser;

        if (currentUser) {
          if (await currentUser.emailVerified) {
            next();
          } else {
            next("/");
          }
        } else {
          next("/register");
        }
      } else {
        next();
      }
    });
  });
});

const handleSignOut = () => {
  signOut(auth).then(() => {
    router.push("/");
  });
};
const goBack = () => {
  router.go(-1);
};
</script>
<style lang="scss">
.logo {
  height: 110px;
}
nav {
  position: fixed;
  z-index: 5;
  bottom: 0;
  width: 100%;
  background: #3574e7;
  display: flex;
  justify-content: center;

  button {
    border-radius: 0;
    border: 0;
    margin: 0;
    text-align: center;
    height: 4.2em;
    line-height: 1.2em;

    &.back {
      width: 25%;
      max-width: 50px;
      align-self: flex-start;
    }

    svg {
      display: block;
    }
  }
}

.blurred-button {
  background: #d5daff;
  color: #8187b9;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  background: #efefef;
}

h1 {
  font-size: 24px;
  padding-left: 3px;
}

a {
  text-decoration: none;
}

.wrapper {
  padding: 0 0 50px;
  max-width: 1280px;
  margin: 0 auto;
}

.relative {
  position: relative;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3b4a87;
  font-size: 0.8em;

  @media (max-width: 450px) {
    font-size: 0.9em;
  }
}

.submit {
  float: right;
}

.flex {
  display: flex;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.flex-wrap-between {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

input,
textarea,
select {
  border: 1px solid #efefef;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

input[type="number"] {
  width: 50px;

  &.price {
    width: 65px;
  }
}

fieldset select {
  height: 35px;
  text-align: center;
}

form {
  //position: fixed;
  z-index: 1000;
  background: #eef0ff;
  padding: 0.5em;
  display: inline-block;
  min-width: 285px;
  width: 90%;
}

fieldset {
  max-width: 400px;
  background: #eef0ff;
  border: none;
  border-radius: 8px;
  padding: 0;
  margin: 0;

  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="email"],
  input[type="tel"],
  textarea {
    display: inline-block;
    line-height: 20px;
    height: 3.2em;
    margin-top: 5px;
    padding: 0 5%;
    width: 38%;
    border-radius: 8px;
    color: #064687;

    &.wide {
      width: 86.2%;
    }
  }
}

textarea {
  height: 60px;
  width: 90%;
  margin-bottom: 10px;
}

fieldset input[type="number"] {
  display: inline-block;
}

button,
.button {
  border: none;
  border-radius: 5px;
  background: #3574e7;
  height: 3.2em;
  line-height: 3.2em;
  padding: 1px 16px;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  margin: 0 2px;

  &.remove {
    color: #ff4e4e;
    background: #ffdfdf;
  }

  &.edit {
    background: #a6c1ff;
    color: #ffffff;
  }

  &.cancel {
    background:#e1e1e1;
    color: grey;
  }

  a,
  & {
    color: #ffffff;
    text-decoration: none;
  }
}
.price {
  font-weight: bold;
}
.list-item {
  border-radius: 10px;
  margin: 7px;
  line-height: 20px;
  display: block;
  text-decoration: none;
  background: white;
  width: 97%;
  max-width: 500px;
  box-shadow: 0 0 21px -6px #afb6d7;
  overflow: hidden;

  .title {
    color: #3574e7;
    font-size: 1.2em;
  }

  .infos {
    padding: 0.5em 0 0.5em 1em;

    ul,
    li {
      padding: 0;
      margin: 0;
    }

    li {
      list-style: none;
    }

    .observations {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 180px;
    }
  }
}
.add-button {
  position: fixed;
  top: 10px;
  right: 10px;
  border: 1px solid white;
  z-index: 1;
}
::placeholder {
  color: #808d95;
}
.bright {
  color: #c1c1c1;
}
.hidden {
  display: none;
}
.notification {
  background: #ffd7a5;
  padding: 25px;
  color: #6f331b;
  font-size: 15px;
  text-align: center;
}
.light-notif {
  background: #ffd7a5;
  color: red;
  font-size: 13px;
  padding: 0 20px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;

  a {
    color: red;
  }
}
//fonts
// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 200 to 1000

.nunito {
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}
.potentials {
  h3 {
    padding-left: 10px;
  }
}
</style>
