<template>
  <div class="flex-wrap">
    <div class="infos-page">
      <div class="img-wrapper" v-if="property.picture || isEdited">
        <img
          :src="`https://linkimmopro.fr/uploads/${property.picture}`"
          alt=""
        />
        <FileUpload
          v-if="isEdited"
          class="uploader"
          :propertyID="property.id"
          ref="uploader"
          @upload-complete="submitImageOnUpload"
          @upload-needed="() => (imageWaiting = true)"
          @upload-canceled="() => (imageWaiting = false)"
        />
      </div>
      <div class="page-infos flex-wrap-between">
        <section class="picture" v-if="!isEdited">
          <div class="name">
            <font-awesome-icon icon="house" /> {{ property.propertyType }}
          </div>
          <div class="criterias">
            <div class="details">
              <div v-if="property.price">
                <strong>{{ property.price }} €</strong>
              </div>
              <span v-if="property.surface">{{ property.surface }}m²</span>
              <span v-if="property.bedrooms"
                >{{ property.bedrooms }} Chambre<span
                  v-if="property.bedrooms > 1"
                  >s</span
                ></span
              >
              <span v-if="property.rooms"
                ><br />{{ property.rooms }} Pièce<span v-if="property.rooms > 1"
                  >s</span
                >
              </span>
              <span v-if="property.levels"
                >{{ property.levels }} Etage<span v-if="property.levels > 1"
                  >s</span
                ></span
              >
            </div>
            <div class="adress">
              <span v-if="property.adress" class="spaced"
                >{{ property.adress }}
              </span>
              <span>
                {{ property.city }}
              </span>
              <span class="spaced">
                {{ property.observations }}
              </span>
            </div>
          </div>
        </section>

        <!-- editable -->

        <section v-if="isEdited">
          <div class="type-selector">
            <input
              type="radio"
              name="propertyType"
              v-model="property.propertyType"
              id="maison"
              value="maison"
            />
            <label for="maison" class="blurred-button">Maison</label>

            <input
              type="radio"
              name="propertyType"
              v-model="property.propertyType"
              value="appartement"
              id="appartement"
            />
            <label for="appartement" class="blurred-button">Appartement</label>

            <input
              type="radio"
              name="propertyType"
              v-model="property.propertyType"
              value="parking"
              id="parking"
            />
            <label for="parking" class="blurred-button">Parking</label>

            <input
              type="radio"
              name="propertyType"
              v-model="property.propertyType"
              value="terrain"
              id="terrain"
            />
            <label for="terrain" class="blurred-button">Terrain</label>
          </div>
          <div class="flex-wrap">
            <div>
              <input
                type="number"
                class="price"
                placeholder="Prix"
                v-model="property.price"
                name=""
                id=""
              />
              €
              <input type="number" v-model="property.surface" name="" id="" />
              m² -
              <input type="number" v-model="property.bedrooms" name="" id="" />
              Chambre <span v-if="property.bedrooms > 1">s</span><br />
              <input type="number" v-model="property.rooms" name="" id="" />
              Pièce<span v-if="property.rooms > 1">s</span> -
              <input type="number" v-model="property.levels" name="" id="" />
              Etage<span v-if="property.levels > 1">s</span> <br />
              <input
                type="text"
                v-model="property.adress"
                placeholder="Adresse"
              />
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                v-model="property.observations"
              ></textarea>
              <br />
              <div class="relative">
                <div
                  class="city-selector"
                  v-if="
                    citiesFromZipcode.length > 0 || citiesFromName.length > 0
                  "
                >
                  <div
                    v-for="city in citiesFromZipcode"
                    :key="city"
                    @click="setCity(city.label, city.zip_code)"
                  >
                    {{ city.zip_code }} - {{ city.label }}
                  </div>
                  <div
                    v-for="city in citiesFromName"
                    :key="city"
                    @click="setCity(city.label, city.zip_code)"
                  >
                    {{ city.zip_code }} - {{ city.label }}
                  </div>
                </div>
                <input
                  placeholder="Ville"
                  type="text"
                  name=""
                  id="zipcode"
                  v-model="property.city"
                  @focus="clearZip()"
                />
              </div>
            </div>
          </div>
        </section>
        <section class="actions" v-if="userRole">
          <button class="edit" @click="isEdited = true" v-if="!isEdited">
            Modifier
          </button>
          <button @click="checkUpload()" v-if="isEdited">Enregistrer</button>
          <button class="remove" v-if="!isEdited" @click="removeProperty()">Supprimer</button>
          <button v-if="isEdited" class="cancel" @click="isEdited = false">Annuler</button>
        </section>
        <div v-else class="light-notif">
          <router-link to="/prices"> Mode restreint </router-link>
        </div>
      </div>
    </div>
      <PopinConfirm
        v-if="isPopinShown"
        title="Suppression du bien"
        content="confirmez vous ?"
        cancellable="true"
        :label="confirmLabel"
        @confirm="confirmRemoval"
        @cancel="isPopinShown = false"
      />
    <div class="potentials" v-if="matchingContacts.length>0">
      <h3>Contacts potentiels</h3>
      <div class="flex-wrap">
        <ContactList :list="matchingContacts" />
      </div>
    </div>
  </div>
</template>

<script>
import ContactList from "@/components/contact/ContactList.vue";
import PopinConfirm from "@/components/utils/PopinConfirm.vue";
import FileUpload from "../utils/FileUpload.vue";
import cityList from "@/assets/cities.json";
export default {
  components: { ContactList, PopinConfirm, FileUpload },
  data() {
    return {
      isPopinShown: false,
      isEdited: false,
      confirmLabel: "propertyRemove",
      propertyList: [],
      targetZipcode: [],
      imageWaiting: false,
    };
  },
  mounted() {
    console.log("state", this.$store.state);
  },
  computed: {
    userRole() {
      return this.$store.state.stripeRole;
    },
    propertyListfromStore() {
      return this.$store.state.propertyList;
    },
    contactListfromStore() {
      return this.$store.state.contactList;
    },
    property() {
      return this.$store.getters.getPropertyFromID(this.$route.params.id);
    },
    citiesFromZipcode() {
      let cities = [];
      if (this.property.city.length > 2) {
        cities = Object.values(cityList.cities).filter((city) =>
          city["zip_code"].startsWith(this.property.city)
        );
      }

      return cities;
    },
    citiesFromName() {
      let cities = [];
      if (this.property.city.length > 2) {
        cities = Object.values(cityList.cities).filter((city) =>
          city["label"].includes(this.property.city)
        );
      }

      return cities;
    },
    matchingContacts() {
      let list = this.contactListfromStore;
      list = list.filter((el) =>
        el.contactCityList.find((it) => it == this.property.city)
      );
      return list;
    },
    index() {
      return this.$route.params.id;
    },
  },

  methods: {
    checkUpload() {
      if (this.imageWaiting) {
        this.$refs.uploader.uploadFile();
      } else {
        this.saveProperty();
      }
    },
    saveProperty() {
      this.$store.dispatch("savePropertyList");
      this.isEdited = false;
    },
    submitImageOnUpload(imageName) {
      console.log("received: ", imageName);
      this.imageWaiting = false;
      this.property.picture = imageName;
      this.saveProperty();
    },
    removeProperty() {
      this.isPopinShown = true;
    },
    confirmRemoval(data) {
      const targetPropertyIndex = this.propertyList.indexOf(this.property);
      if (data == this.confirmLabel) {
        this.propertyList.splice(targetPropertyIndex, 1);
        this.saveProperty();
        this.$router.push("/properties");
      }
    },
    clearZip() {
      this.property.city = "";
    },
    setCity(city, zipcode) {
      this.property.city = zipcode + " " + city;
    },
    removeCity(index) {
      this.contactCityList.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.uploader {
  position: absolute;
}
.img-wrapper {
  max-height: 300px;
  max-width: 500px;
  min-height: 200px;
  background: #d5daff;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    width: 100%;
  }
}
.criterias .details {
  span + span:before {
    content: " - ";
  }
}
.details {
  padding: 10px 0;
}
.adress {
  display: block;
  .spaced {
    &:before {
      content: "";
    }
    display: block;
    margin-top: 10px;
  }
}
</style>
