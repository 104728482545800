<template>
  <div
    v-for="contact in list"
    :key="contact"
    class="list-item contact"
    @click="navigate(`/contact/${contact.id}`)"
  >
    <font-awesome-icon icon="user" class="icon" />
    <strong class="name">{{ contact.name }} {{ contact.firstname }}</strong>
    <div class="contact-infos">
    <div v-if="contact.budget">Budget : {{ contact.budget }} €</div>
      <div
        class="search-types"
        v-if="
          contact.isHouse ||
          contact.isAppartment ||
          contact.isParking ||
          contact.isLand
        "
      >
        Citères : 
        <span v-if="contact.isHouse">Maison</span>
        <span v-if="contact.isAppartment">Appartement</span>
        <span v-if="contact.isParking">Parking</span>
        <span v-if="contact.isLand">Terrain</span>
      </div>
      <div class="flex-wrap">
        <div
          class="city-item"
          v-for="city in contact.contactCityList"
          :key="city"
        >
          {{ city }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  props: {
    list: {},
  },
  data() {
    return {
      cool: true,
    };
  },
  methods: {
    navigate(target) {
      router.push(target);
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  position: relative;

  .icon {
    position: absolute;
    top: 13px;
    right: 13px;
    color: #6d83df;
  }
  .name {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    display: block;
    color: #3574e7;
    padding: 10px 0 0 10px;
    font-size: 1.2em;
    text-transform: capitalize;
  }
  .contact-infos {
    padding: 10px;
  }
  .search-types {
    span+span:before {
        content: " - "
    }
}
}
</style>
