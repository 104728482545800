import { createRouter, createWebHashHistory } from "vue-router";
import PropertyForm from "@/components/property/PropertyForm";
import PropertyPage from "@/components/property/PropertyPage";
import ContactPage from "@/components/contact/ContactPage";
import ContactForm from "@/components/contact/ContactForm";
import HomeView from "@/views/HomeView"
//import PriceSelectionVue from "@/components/PriceSelection.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import( "../views/AboutView.vue"),
  },
  {
    path: "/properties",
    component: PropertyForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contacts",
    component: ContactForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/property/:id",
    component: PropertyPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contact/:id",
    component: ContactPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/register",
    component: () =>
      import( "@/views/RegisterForm.vue"),
  },
  {
    path: "/login",
    component: () =>
      import( "@/views/LoginPage.vue"),
  },
  {
    path: "/info",
    component: () =>
      import( "@/views/DashboardPage.vue"),
  },
  {
    path: "/dashboard",
    component: () =>
      import(
         "@/views/DashboardPage.vue"
      ),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/prices",
    component: () =>
      import( "@/components/PriceSelection.vue"),
    meta: {
      requiresAuth: false,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
export default router;
