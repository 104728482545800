<template>
  <div class="flex-wrap">
    <div class="infos-page">
      <div class="page-infos flex-wrap cont">
        <div v-if="!isEdited && contact">
          <div class="name">
            <font-awesome-icon icon="user" />
            {{ contact.firstname }} {{ contact.name }}
          </div>
          <div class="mailtel">
            <a :href="`mailto:${contact.email}`"> {{ contact.email }} </a>
            <br />
            <a :href="`tel:${contact.phone}`"> {{ contact.phone }} </a>
          </div>
          <div class="budget" v-if="contact.budget">
            <strong>Budget :</strong> {{ contact.budget }} €
          </div>
          <div
            class="search-types"
            v-if="
              contact.isHouse ||
              contact.isAppartment ||
              contact.isParking ||
              contact.isLand
            "
          >
            <strong>Type de bien recherché </strong><br />
            <span v-if="contact.isHouse">Maison</span>
            <span v-if="contact.isAppartment">Appartement</span>
            <span v-if="contact.isParking">Parking</span>
            <span v-if="contact.isLand">Terrain</span>
          </div>
          <div class="cities">
            <strong>Secteur</strong>
            <div class="flex-wrap">
              <div class="flex-wrap">
                <div
                  class="city-item"
                  v-for="city in contact.contactCityList"
                  :key="city"
                >
                  {{ city }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="contact.observations" class="observations">
            "{{ contact.observations }}"
          </div>
        </div>
        <!-- editable -->
        <div v-if="isEdited">
          <div class="name">
            <font-awesome-icon icon="user" />
            <input type="text" v-model="contact.firstname" /><input
              type="text"
              v-model="contact.name"
            />
          </div>
          <div class="mailtel">
            <input type="email" name="" id="" v-model="contact.email" />
            <br />
            <input type="text" name="" id="" v-model="contact.phone" />
          </div>
          <div class="budget">
            <strong>Budget :</strong>
            <input type="text" name="" v-model="contact.budget" id="" /> €
          </div>
          <div class="search-types">
            <strong>Type de bien recherché </strong><br />
            <input type="checkbox" v-model="contact.isHouse" id="" /><span
              >Maison</span
            >
            <input type="checkbox" v-model="contact.isAppartment" id="" /><span
              >Appartement</span
            >
            <input type="checkbox" v-model="contact.isParking" id="" /><span
              >Parking</span
            >
            <input type="checkbox" v-model="contact.isLand" id="" /><span
              >Terrain</span
            >
          </div>
          <div class="cities">
            <div class="relative">
              <div
                class="city-selector"
                v-if="citiesFromZipcode.length > 0 || citiesFromName.length > 0"
              >
                <div
                  v-for="city in citiesFromZipcode"
                  :key="city"
                  @click="setCity(city.label, city.zip_code)"
                >
                  {{ city.zip_code }} - {{ city.label }}
                </div>
                <div
                  v-for="city in citiesFromName"
                  :key="city"
                  @click="setCity(city.label, city.zip_code)"
                >
                  {{ city.zip_code }} - {{ city.label }}
                </div>
              </div>
              <input
                placeholder="Code postal de recherche"
                type="text"
                name=""
                id="zipcode"
                v-model="targetZipcode"
                @focus="clearZip()"
              />
            </div>
            <div>
              <div class="flex-wrap">
                <div
                  class="city-item"
                  v-for="(city, index) in contact.contactCityList"
                  :key="city"
                  @click="removeCity(index)"
                >
                  {{ city }} <span>&#10006;</span>
                </div>
              </div>
            </div>
          </div>
          <textarea
            name=""
            id=""
            cols="30"
            rows="5"
            placeholder="Informations complémentaires"
            v-model="contact.observations"
          ></textarea>
        </div>
        <div class="actions" v-if="userRole">
          <button
            class="edit"
            v-if="isEdited == false"
            @click="isEdited = true"
          >
            Modifier
          </button>
          <button v-if="isEdited" class="save" @click="saveContact()">
            Enregistrer
          </button>
          <button v-if="!isEdited" class="remove" @click="removeContact()">
            Supprimer
          </button>
          <button v-if="isEdited" class="cancel" @click="isEdited = false">
            Annuler
          </button>
        </div>
        <div v-else class="light-notif">
          <router-link to="/prices"> Mode restreint </router-link>
        </div>
      </div>
    </div>
      <PopinConfirm
        v-if="isPopinShown"
        title="Suppression du contact"
        content="confirmez vous ?"
        cancellable="true"
        :label="confirmLabel"
        @confirm="confirmRemoval"
        @cancel="isPopinShown = false"
      />
    <div class="potentials" v-if="matchingProperties.length > 0">
      <h3>En accord avec les critères</h3>
      <PropertyList :list="matchingProperties" />
    </div>
  </div>
</template>

<script>
import PropertyList from "@/components/property/PropertyList.vue";
import PopinConfirm from "@/components/utils/PopinConfirm.vue";
import cityList from "@/assets/cities.json";
export default {
  components: { PropertyList, PopinConfirm },
  data() {
    return {
      confirmLabel: "contactRemove",
      targetZipcode: "",
      isPopinShown: false,
      isEdited: false,
    };
  },
  computed: {
    userRole() {
      return this.$store.state.stripeRole;
    },
    contactListfromStore() {
      return this.$store.state.contactList;
    },
    propertyListfromStore() {
      return this.$store.state.propertyList;
    },
    contact() {
      return this.$store.getters.getContactFromID(this.$route.params.id);
    },
    citiesFromZipcode() {
      let cities = [];
      if (this.targetZipcode.length > 2) {
        cities = Object.values(cityList.cities).filter((city) =>
          city["zip_code"].startsWith(this.targetZipcode)
        );
      }
      return cities;
    },
    citiesFromName() {
      let cities = [];
      if (this.targetZipcode.length > 2) {
        cities = Object.values(cityList.cities).filter((city) =>
          city["label"].includes(this.targetZipcode)
        );
      }

      return cities;
    },
    matchingProperties() {
      let list = this.propertyListfromStore;
      let matchs = [];
      if (this.contact) {
        const cityList = this.contact.contactCityList;
        for (let index = 0; index < cityList.length; index++) {
          const city = cityList[index];
          matchs.push(list.filter((el) => city == el.city));
          matchs = matchs.flat();
        }
      }
      return matchs;
    },
  },
  methods: {
    saveContact() {
      this.$store.dispatch("saveContactList");
      this.isEdited = false;
    },
    removeContact() {
      console.log('rrrrr',this.isPopinShown)
      this.isPopinShown = true;
    },
    async confirmRemoval(data) {
      const targetContactIndex = this.contactListfromStore.indexOf(
        this.contact
      );
      if (data == this.confirmLabel) {
        this.contactListfromStore.splice(targetContactIndex, 1);
        await this.saveContact();
        this.$router.push("/contacts");
      }
    },
    setCity(city, zipcode) {
      this.contact.contactCityList.push(zipcode + " " + city);
      this.clearZip();
    },
    clearZip() {
      this.targetZipcode = "";
    },
    removeCity(index) {
      this.contact.contactCityList.splice(index, 1);
      console.log("remove", this.contact.contactCityList);
    },
  },
};
</script>

<style lang="scss">
.infos-page {
  max-width: 500px;
  min-width: 340px;
  position: relative;
  .name {
    font-size: 2.2em;
    text-transform: capitalize;
    color: #3574e7;
  }

  .page-infos {
    box-shadow: 0 0 20px -10px grey;
    padding: 20px;
    background: white;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;

    &.cont {
      border-radius: 20px;
    }
  }

  .mailtel {
    a {
      display: inline-block;
      background: #cbffcb;
      padding: 3px 9px;
      color: green;
      margin: 2px 0;
      border-radius: 3px;
    }
  }
  .actions {
    margin-left: 10px;
  }
  .mailtel,
  .search-types,
  .cities,
  .actions,
  .budget,
  .observations,
  textarea {
    margin-top: 10px;
  }
  .search-types {
    span + span:before {
      content: " - ";
    }
  }
  //form
  input[type="email"],
  input[type="text"],
  input[type="number"] {
    height: 2.5em;
    margin: 2px;
    border-color: #a6c1ff;
    border-radius: 4px;
    text-indent: 5px;
  }
  textarea {
    border: 1px solid #a6c1ff;
  }
  .name {
    input {
      width: 40%;
      height: 1.6em;
      font-size: 0.8em;
    }
  }
}
</style>
