<template>
    <div class="layout" @click="emitCancel()">
        <div class="popin">
            <div class="title">{{ title }}</div>
            <div class="content" >
                {{ content }}
                <div>
                    <button @click="emitConfirm()">Confirmer</button><button v-if="cancellable" @click="emitCancel()">Annuler</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: String,
        content: String,
        label: String,
        cancellable: Boolean,
    },
    methods: {
        emitConfirm() {
            this.$emit('confirm',this.label)
        },
        emitCancel() {
            this.$emit('cancel',this.label)
        },
    }
}
</script>
<style lang="scss">
.popin {
    background: white;
    position: fixed;
    width: 80%;
    padding: 20px;
    box-shadow: 0 0 11px -4px #6f6f6f;
    text-align: center;
}
.layout {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    left:0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.384);
}
</style>