<template>
  <div class="home">
    <h2>Link Immo PRO</h2>
    <div class="presentation">
      <h1>Connectez vos biens et vos contacts en un clin d'œil</h1>
      <div class="aligner svg">
        <font-awesome-icon icon="list-check" />
      </div>
      <p>
        Une interface intuitive pour gérer vos biens, vos contacts et les faire correspondre en un seul
        clic.
      </p>
      <div class="aligner svg">
        <font-awesome-icon icon="mobile-screen-button" />
        <font-awesome-icon icon="laptop" />
        <font-awesome-icon icon="tablet-screen-button" />
      </div>
      <p>Accessible sur smartphone, tablette, PC</p>
      <p>Prenez une longueur d'avance dans votre activité immobilière.</p>
      <div class="aligner">
        <button @click="this.$router.push('/register')">S'inscrire <font-awesome-icon icon="check" /> </button>
      </div>
    </div>
  </div>
</template>

<script setup></script>
<style lang="scss">
.home {
  background: url(@/assets/background.png) no-repeat;
  height: 100vh;
  background-position: center center;
  background-size: cover;
  max-width: 400px;
  margin: 0 auto;
  h2 {
    padding: 20px;
    margin: 0;
    color: white;
    font-size: 2em;
    text-shadow: 0 2px 17px BLACK;
  }
  h1 {
    color: #637dad;
    font-size: 1.4em;
  }
  p {
    font-size: 1.3em;
    font-weight: 300;
    margin: 0 0 45px 0;
    color: #3f5c91;
  }
  .aligner {
    text-align: center;
    font-size: 2em;
    display: flex;
    justify-content: space-evenly;

    &.svg {
    svg {
      background: #a0b5db;
      color: white;
      padding: 14px;
      border-radius: 60px;
      width: 30px;
      height: 30px;
    }
    }
  }
}
.presentation {
  text-align: center;
  background: rgba(255, 255, 255, 0.83);
  border-radius: 45px;
  padding: 30px;
  margin: 15px;
  margin-top: 50px;
}
</style>
