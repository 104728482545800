<template>
    <div class="list-item property-item" v-for="(property, index) in list" :key="index">
        <div class="property-wrapper" @click="navigate(`/property/${property.id}`)">
            

            <div class="infos">
                <div class="flex-between">
                    <div class="title" v-html="abrMapping[property.propertyType]"></div>
                    <span class="price" v-if="property.price">{{ property.price }} €</span>
                </div>
                <span v-if="property.surface">{{ property.surface }}m²</span>
                <span v-if="property.rooms">{{ property.rooms }}&nbsp;pièces </span><br>
                <span v-if="property.bedrooms">{{ property.bedrooms }}&nbsp;chambres</span>
                <span v-if="property.levels">{{ property.levels }}&nbsp;Niv</span><br>
                <span v-if="property.city"><strong>{{ property.city }}</strong></span>
                <div class="observations">{{ property.observations }}</div>
            </div>
            <div class="picture-wrapper" v-if="property.picture">
                <img :src="`https://linkimmopro.fr/uploads/${property.picture}`" alt="">
            </div>
            <div class="filler" v-else>
                <font-awesome-icon icon="house" /> &nbsp;
            </div>
        </div>
    </div>
</template>
<script>
import router from '@/router'

export default {
    props: {
        list: {}
    },
    data() {
        return {
            abrMapping: {
                "appartement": "Appt.",
                "maison": "Maison",
                "parking": "Parking",
                "terrain": "Terrain"
            }
        }
    },
    methods: {
        navigate(target) {
            router.push(target)
        },
    }
}
</script>
<style lang="scss">
.property-wrapper {
    display: grid;
    grid-template-columns: 4fr 3fr;
    grid-auto-flow:column;
    align-items: center;
    min-height: 135px;

    .title {
        font-weight: bold;
    }
    .price {
        margin-right: 10px;
        color: #3574e7;
    }
}
.filler {
    height: -webkit-fill-available;
    background: linear-gradient(-55deg, #d3d6ff, white);
    font-size: 26px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #a8c3f3;
}
.picture-wrapper {
    width: 100%;
    max-height: 135px;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
        width: 165%;
    }
}
  .infos {
    span + span:before {
      content: " - ";
    }
  }
</style>