import { createStore } from "vuex";
import { doc, setDoc, getDoc, onSnapshot } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../main";

const store = createStore({
  state() {
    return {
      contactList: [],
      propertyList: [],
      stripeRole: null,
      userInfos: {}
    };
  },
  mutations: {
    setContactList(state, payload) {
      state.contactList = JSON.parse(payload.JSONdatas);
    },
    addContact(state, payload) {
      state.contactList.push(payload);
    },
    setPropertyList(state, payload) {
      state.propertyList = JSON.parse(payload.JSONdatas);
    },
    addProperty(state, payload) {
      state.propertyList.push(payload);
    },
    setStripeRole(state,payload) {
      console.log('stripe role p',payload)
      state.stripeRole = payload
    },
    setUserInfos(state, payload) {
      state.userInfos = payload
    }
  },
  actions: {
    async getCustomClaimRole(context) {
      console.info('Getting role')
      const auth = getAuth();
      auth.currentUser?.reload();
      //await auth.currentUser?.getIdTokenResult(true);
      const decodedToken = await auth.currentUser?.getIdTokenResult(true);
      auth.currentUser?.reload();
      context.commit('setStripeRole', decodedToken?.claims.stripeRole)
    },
    async realtimeRetrieveContacts(context) {
      const docRef = doc(db, "contactLists", getAuth().currentUser.uid);
      await onSnapshot(docRef, (doc) => {
        console.info("Get contact page snap shot: ", doc.data());
        const datas = doc.data();
        context.commit("setContactList", datas);
      });
    },
    async realtimeRetrieveProperties(context) {
      const docRef = doc(db, "propertyLists", getAuth().currentUser.uid);
      await onSnapshot(docRef, (doc) => {
        console.info("Get contact page snap shot: ", doc.data());
        const datas = doc.data();
        context.commit("setPropertyList", datas);
      });
    },
    async saveContactList(context) {
      const data = JSON.stringify(context.state.contactList);
      if (data) {
        try {
          await setDoc(doc(db, "contactLists", getAuth().currentUser.uid), {
            JSONdatas: data,
            lastModified: Date.now(),
          });
        } catch (err) {
          console.error("save contact error", err);
          alert("Une erreur est survenue: ", err);
        }
      }
    },
    async savePropertyList(context) {
      const data = JSON.stringify(context.state.propertyList);
      if (data) {
        try {
          await setDoc(doc(db, "propertyLists", getAuth().currentUser.uid), {
            JSONdatas: data,
            lastModified: Date.now(),
          });
        } catch (err) {
          console.error("save property error", err);
          alert("Une erreur est survenue: ", err);
        }
      }
    },
    async retrieveInfos(context) {
      console.log('useeeeeer',getAuth().currentUser)
      console.log("Retrieve user infos online")
      const currentUser = getAuth().currentUser
      const docRef = doc(db, "userInfos", currentUser.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
          const datas = docSnap.data();
          context.commit('setUserInfos', datas.JSONdatas)
      } else if(currentUser.providerData){
        context.commit('setUserInfos', currentUser.providerData[0])
      } else {
          console.log("No such document!");
      }
  },
    addContact(context, payload) {
      context.commit("addContact", payload);
    },
    addProperty(context, payload) {
      context.commit("addProperty", payload);
    },
  },
  getters: {
    getMatchingContacts(propertyID) {
      console.log(propertyID);
    },
    getMatchingProperties(contactID) {
      console.log(contactID);
    },
    getContactFromID: (state) => (id) => {
      return state.contactList.find((item) => item.id == id);
    },
    getPropertyFromID: (state) => (id) => {
      return state.propertyList.find((item) => item.id == id);
    },
  },
});

export default store;
