import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import router from "./router";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

//icons
import {
  faHouse,
  faCar,
  faCamera,
  faXmark,
  faStairs,
  faPlus,
  faRightToBracket,
  faChevronLeft,
  faInfo,
  faListCheck,
  faTabletScreenButton,
  faLaptop,
  faMobileScreenButton,
  faCheck
} from "@fortawesome/free-solid-svg-icons";
// Create a new store instance.
import store from "@/store/";

// Install the store instance as a plugin
import firebaseConfig from "./firebaseConfig";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };

library.add(faHouse);
library.add(faCar);
library.add(faUser);
library.add(faCamera);
library.add(faXmark);
library.add(faStairs);
library.add(faPlus);
library.add(faRightToBracket);
library.add(faChevronLeft);
library.add(faInfo);
library.add(faListCheck);
library.add(faTabletScreenButton);
library.add(faMobileScreenButton);
library.add(faLaptop);
library.add(faCheck);

createApp(App)
  .use(store)
  .use(router)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
